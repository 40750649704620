import * as React from "react"
import { Link, graphql } from "gatsby"
import { StaticImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import TextLeft from "../components/TextLeft"
import TextRight from "../components/TextRight"
import { MdOutlineSouth } from 'react-icons/md';

const Home = ( {data} ) => {

  const logHomeImage = getImage(data.logHomeImage)
  const lumberImage = getImage(data.lumberImage)

  return (
    <Layout formTitle="Let us know how we can help with your dream log home.">
      <Seo 
        title="Custom Log Home Design and Lumber Mill"
        description="A family owned and operated log home design and lumber mill located in Vermont. We manufacture quality custom log homes and dimensional milled lumber." 
      />
      <div className="relative pt-32 md:pt-40 overflow-hidden">
        <div className="container mx-auto">
          <div className="flex flex-col md:grid md:grid-cols-7 gap-12 align-items-center md:gap-20">
            <div className="flex flex-col col-span-4 xl:mt-24">
              <h1 className="max-w-xl">Authentic Log Homes of Vermont</h1>
              <p>A family owned and operated log home design and lumber mill located in the scenic Northeast Kingdom of Vermont. We manufacture quality custom log homes and dimensional milled lumber.</p>
              <Link to="/contact" className="main-btn">Contact Us</Link>
              <div className="flex flex-col justify-end mt-12 xl:mt-24 -ml-3">
                <span className="scroll-icon">Scroll</span>
                <MdOutlineSouth className="text-4xl"/>
              </div>
            </div>
            <div className="flex flex-col items-end col-span-3">
              <StaticImage
                src="../images/home_header_lg.jpg" 
                alt="a custom log home in Vermont"
                placeholder="blurred"
                layout="fullWidth"
                quality={100}
                className="w-4/5"
              />
              <StaticImage
                src="../images/home_header_sm.jpg"
                alt="a custom log home in Vermont"
                placeholder="blurred"
                layout="fullWidth"
                quality={100}
                className="w-2/3 -translate-y-1/2 -translate-x-1/2 md:-translate-x-2/3"
              />
            </div>
          </div>
        </div>
        <div className="wood-grain-bg">
          <StaticImage
            src="../images/wood_grain_bg.svg"
            alt=""
            placeholder="blurred"
            layout="fullWidth"
            quality={100}
          />
        </div>
      </div>
      <div className="container mx-auto mt-32 md:mt-40">
        <h2 className="md:text-center max-w-5xl md:mx-auto">Unparalleled craftsmanship that produces character rich log homes and lumber</h2>
        <p className="md:text-center max-w-3xl md:mx-auto">This is attained through a "hands-on" processing approach. We rely on the skill of experienced craftsmen to create quality and affordable log homes and lumber. The result is a product that is natural, beautiful, and enduring.</p>
        <TextRight
          heading={<h3>Custom Log Homes</h3>}
          subHeading={<p>An Authentic Log Home is the perfect choice if you value quality, affordability, and truly personal custom design.</p>}
          image={logHomeImage}
          altText="a custom log home in Vermont"
          buttonClass="secondary-btn"
          buttonText="Learn more about our log homes"
          buttonLink="/log-homes"
          addedClasses="md:items-center mt-24 md:mt-32"
        />
        <TextLeft
          heading={<h3>Lumber &amp; Timber</h3>}
          subHeading={<p>We sell top quality cedar, pine, spruce, and fir lumber directly from our mill in Hardwick, VT.</p>}
          image={lumberImage}
          altText="a pile of lumber in Vermont"
          buttonClass="secondary-btn"
          buttonText="Learn more about our lumber"
          buttonLink="/lumber"
          addedClasses="md:items-center mt-24 md:mt-32"
        />
      </div>
      <div className="relative bg-color py-24 md:py-32 mt-32 md:mt-40">
        <div className="container mx-auto">
          <h2 className="text-center mx-auto mb-20">What makes our Vermont log homes different.</h2>
          <iframe className="w-full aspect-video" src="https://www.youtube.com/embed/Yg-p7RhMBWU" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
      </div>
    </Layout>
  );
}
 
export default Home;

export const pageQuery = graphql`
  query {
    logHomeImage: file(relativePath: { eq: "log_home.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          )
      }
    },
    lumberImage: file(relativePath: { eq: "lumber.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          )
      }
    }
  }
`
import React from "react";
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import { MdChevronRight } from 'react-icons/md';

const TextRight = ( {preHeading, heading, subHeading, image, altText, buttonClass, buttonText, buttonLink, addedClasses} ) => {
  return ( 
    <>
    <div className={`flex flex-col md:grid md:grid-cols-2 gap-12 md:gap-20 ${addedClasses}`}>
      <div>
        <GatsbyImage 
          image={image}
          alt={altText}
        />
      </div>
      <div>
        {preHeading && <span className="uppercase text-xl font-light color-secondary">{preHeading}</span>}
        {heading && heading}
        {subHeading && subHeading}
        {buttonText && buttonLink &&
          <Link to={buttonLink} className={buttonClass}>{buttonText} {buttonClass === "secondary-btn" && <span><MdChevronRight /></span>}</Link>
        }
      </div>
    </div>
    </>
   );
}
 
export default TextRight;